export const getFeaturesByGroupNames = (groupNames, groups, request) => groups
  .filter((group) => groupNames.includes(group.name))
  .map((group) => {
    group.features = group.features.map((feature) => {
      const propertyFeature = {
        request_id: request.id,
        feature,
        feature_id: feature.feature.id,
        feature_group_id: group.id,
        property_id: request.property.id,
        value: null,
      };
      if (feature.value_type == 'enum') {
        propertyFeature.value_options.options = feature.value_options.options.map((option) => ({
          value: option,
          label: option,
          text: option,
        }));
      }
      return propertyFeature;
    });
    return group;
  });
export const createBlankFeatures = (features, request) => features.map((feature) => ({
  request_id: request.id,
  feature_id: feature.feature.id,
  feature_group_id: group.id,
  property_id: request.property.id,
  value: null,
}));

export const getCurrentFeatures = (groups, featuresTobeFiltered, data) => {
  const features = groups
    .map((group) => group.features)
    .flat()
    .map((feat) => {
      const currentFeature = featuresTobeFiltered.find(
        (filteredFeature) => filteredFeature.feature_id == feat.property_feature_id,
      );
      if (!currentFeature) {
        return {
          request_id: data.request.id,
          feature_id: feat.property_feature_id,
          feature_group_id: feat.feature_group_id,
          property_id: data.property_id,
          value: null,
        };
      }
      return currentFeature;
    });
  return features;
};

export const upsert = (array, element, field = 'id') => {
  const i = array.findIndex((_element) => _element[field] === element[field]);
  if (i > -1) array[i] = element;
  else array.push(element);
  return array;
};

export const updateCollection = (collection, items, field = 'feature_id') => {
  for (const item of items) {
    collection = upsert(collection, item, field);
  }
  return collection;
};

export const evaluationMixin = {
  data() {
    return {
      yesNoOptions: [
        { value: true, text: 'نعم' },
        { value: false, text: 'لا' },
        { value: null, text: 'غير محدد'}
      ],
    }
  },
  methods: {
    // Get feature label
    getFeatureLabel(group, index, label) {
      // if group.options.is_ordered_list exists and its true we prefix label with current number starting from 1
      if (group.options && group.options.is_ordered_list) {
        return `${index + 1} - ${label}`;
      }
      return label;
    },
    getOptions(feature) {
      if (feature.value_options && feature.value_options.options) {
        return feature.value_options.options;
      }
      return this.yesNoOptions;
    },
    validateRules(feature) {
      return true;
    },
    getDisplayProp(feature, prop, defaultValue){
      const value = feature.feature.value_options && feature.feature.value_options.display && feature.feature.value_options.display[prop]? feature.feature.value_options.display[prop]: defaultValue;
      return value;
    },
    castValue(value) {
      // Convert to lower case for case-insensitive comparison
      const lowerCaseValue = value.toLowerCase();
    
      if (lowerCaseValue === 'true') {
        return true; // Cast to boolean true
      } else if (lowerCaseValue === 'false') {
        return false; // Cast to boolean false
      }
      // Return the original value if it's neither "true" nor "false"
      return value;
    },
    checkFieldCondition(feature, current) {
      const { condition } = feature;
      if (!condition) return true;
      // we split the condition field:value
      const [field, value] = condition.split(':');
      // we check if the field exists in current object
      if (!current[field]) return false;
      // we check if the value is equal to the value in the current object
      if (current[field] == value) return true;

      return false;
    },
    checkCondition(feature) {
      if (!feature.condition) return true;
    
      const { condition } = feature;
      const relatedFeature = this.evaluationData.features.find(
        (f) => f.feature_id === condition.related_feature_id,
      );
    
      // Ensure there is a related feature to compare against
      if (!relatedFeature) return false;
    
      // Cast the condition value to its appropriate type (boolean if 'true'/'false', string otherwise)
      const castedConditionValue = this.castValue(condition.value);
    
      // If the casted value is boolean, compare it directly
      if (typeof castedConditionValue === 'boolean') {
        return relatedFeature.value === castedConditionValue;
      }

      
      // If the condition value is a string (not strictly 'true' or 'false') and
      // the related feature's value is also a string or array of strings,
      // check if the related feature's value includes the condition value
      return this.isConditionMet(relatedFeature, castedConditionValue);
    },
    isConditionMet(relatedFeature, conditionValue) {
      // Cast the condition value to its appropriate type (boolean if 'true'/'false', string otherwise)
      const castedConditionValue = this.castValue(conditionValue);
    
      // Check if relatedFeature and relatedFeature.value are truthy
      if (!relatedFeature || relatedFeature.value === undefined || relatedFeature.value === null) {
        return false;
      }
    
      // Function to check if an object is empty
      const isEmptyObject = (obj) => Object.keys(obj).length === 0;
    
      // If the casted value is boolean, compare it directly
      if (typeof castedConditionValue === 'boolean') {
        return relatedFeature.value === castedConditionValue;
      }
    
      // Check if relatedFeature.value is an empty object
      if (typeof relatedFeature.value === 'object' && !Array.isArray(relatedFeature.value) && isEmptyObject(relatedFeature.value)) {
        return false;
      }
    
      // If relatedFeature.value is an Array, check for inclusion of castedConditionValue
      if (Array.isArray(relatedFeature.value)) {
        return relatedFeature.value.includes(castedConditionValue);
      }
    
      // If relatedFeature.value is a string, check if it includes castedConditionValue
      if (typeof relatedFeature.value === 'string') {
        return relatedFeature.value.includes(castedConditionValue);
      }
    
      // If relatedFeature.value is an Object (and not empty), check if any value matches castedConditionValue
      if (typeof relatedFeature.value === 'object') {
        return Object.values(relatedFeature.value).includes(castedConditionValue);
      }
    
      return false; // Default to false if none of the above conditions are met
    },    
    getFeatureOptions(feature, features, currentFeaturesValues) {
      const { options } = feature.feature.value_options;
      // options has a condition if apply the filters: format is operator:related_feature
      if (!feature.feature.value_options.options_conditions) return options;
      const [operator, relatedFeatureName] = feature.feature.value_options.options_conditions.split(':');
      const condition = feature.feature.value_options.options_conditions;
      const relatedFeature = features.find(
        (f) => f.feature.name === relatedFeatureName,
      );
      const currentRelatedFeatureValue = currentFeaturesValues.find((f) => (Array.isArray(f)
        ? f.find((_f) => _f.feature_id === relatedFeature?.feature.id)
        : f.feature_id === relatedFeature?.feature.id));
      if (!currentRelatedFeatureValue) return options;
      switch (operator) {
        case '=': {
          // currentRelatedFeatureValue can be an array or a string
          if (Array.isArray(currentRelatedFeatureValue.value)) {
            return options.filter((option) => currentRelatedFeatureValue.value.includes(option));
          }
          return options.filter(
            (option) => option === currentRelatedFeatureValue.value,
          );
        }
        case '!':
        case '!=': {
          if (Array.isArray(currentRelatedFeatureValue.value)) {
            return options.filter(
              (option) => !currentRelatedFeatureValue.value.includes(value),
            );
          }
          return options.filter(
            (option) => option !== currentRelatedFeatureValue.value,
          );
        }
        case 'in': {
          if (Array.isArray(currentRelatedFeatureValue.value)) {
            return options.filter((option) => currentRelatedFeatureValue.value.includes(option));
          }
          return options.filter(
            (option) => option === currentRelatedFeatureValue.value,
          );
        }
      }
      return options;
    },
    featureChanged(feature) {
    },
    canReview() {
      return (
        this.$can('review', 'evaluation')
        && typeof ['تحت التّقييم', 'مفتوح', 'ملغى'].find(
          (status) => status.localeCompare(this.evaluationData.status, 'ar') == 0,
        ) !== 'undefined'
      );
    },
    canSubmitForApproval() {
      return (
        this.$can('approve', 'evaluation')
        && typeof ['تم التّقييم'].find(
          (status) => status.localeCompare(this.evaluationData.status, 'ar') == 0,
        ) !== 'undefined'
      );
    },
    calculateFeatureIndex(feature) {

      const index = this.evaluationData.features.findIndex(
        (feat) => feat.feature_id == feature.property_feature_id,
      );
      
      return index;
    },
    validateLocation() {
      return new Promise((resolve, reject) => {
        this.$root.$on('locationValidated', ({ success, data }) => {
          if (success) {
            this.evaluationData = { ...this.evaluationData, ...data };
            resolve(true);
          } else {
            reject('الرجاء التأكد من صحة البيانات');
          }
        });
        this.$root.$emit('validateLocation');
      });
    },
    validateProperty() {
      return new Promise((resolve, reject) => {
        this.$root.$on('propertyValidated', ({ success, data }) => {
          if (success) {
            this.evaluationData.features = this.updateCollection(
              this.evaluationData.features,
              data.features,
            );
            resolve(true);
          } else {
            reject('الرجاء التأكد من صحة البيانات');
          }
        });
        this.$root.$emit('validateProperty');
      });
    },
    validateSpecifications() {
      return new Promise((resolve, reject) => {
        this.$root.$on('specificationsValidated', ({ success, data }) => {
          if (success) {
            this.evaluationData.features = this.updateCollection(
              this.evaluationData.features,
              data.features ?? [],
            );
            resolve(true);
          } else {
            reject('الرجاء التأكد من صحة البيانات');
          }
        });
        this.$root.$emit('validateSpecifications');
      });
    },
    validateEvaluation() {
      return new Promise((resolve, reject) => {
        this.$root.$on('evaluationValidated', ({ success, data }) => {
          if (success) {
            this.evaluationData.comments = data.comments;
            this.evaluationData.evaluated_at = data.evaluated_at;
            this.evaluationData.property_description = data.property_description;
            this.evaluationData.approximate_value_per_meter = data.approximate_value_per_meter;
            this.evaluationData.value_per_meter = data.value_per_meter;
            this.evaluationData.features = this.updateCollection(
              this.evaluationData.features,
              data.features,
            );
            this.evaluationData.comparatives = data.comparatives;
            this.evaluationData.areas = data.areas;
            resolve(true);
          } else {
            reject('الرجاء التأكد من صحة البيانات');
          }
        });

        this.$root.$emit('validateEvaluation');
      });
    },
    validateOther() {
      return new Promise((resolve, reject) => {
        this.$root.$on('otherValidated', ({ success, data }) => {
          if (success) {
            this.evaluationData.features = this.updateCollection(
              this.evaluationData.features,
              data.features,
            );
            resolve(true);
          } else {
            reject('الرجاء التأكد من صحة البيانات');
          }
        });

        this.$root.$emit('validateOther');
      });
    },

    validationAttachmentsForm() {
      return new Promise((resolve, reject) => {
        this.$root.$on('attachmentsValidated', ({ success, data }) => {
          if (success) {
            this.evaluationData = { ...this.evaluationData, ...data , attachments : this.evaluationData.attachments.concat(data.attachments )};
            resolve(true);
          } else {
            reject('الرجاء التأكد من صحة البيانات');
          }
        });

        this.$root.$emit('validateAttachments');
      });
    },
    getFeaturesByGroupNames,
    getCurrentFeatures,
    updateCollection,
    upsert,
  },
};
export const evaluationRequestMixin = {
  data() {
    return {
      yesNoOptions: [
        { value: true, text: 'نعم' },
        { value: false, text: 'لا' },
        { value: null, text: 'غير محدد'}
      ],
    }
  },
  methods: {
    // Get evaluation feature index by feature name
    getFeatureIndex(feature) {
      const index = this.evaluationRequestData.features.findIndex(
        (feat) => feat.feature_id == feature.feature.id,
      );
      return index;
    },
    // Get feature label
    getFeatureLabel(group, index, label) {
      // if group.options.is_ordered_list exists and its true we prefix label with current number starting from 1
      if (group.options && group.options.is_ordered_list) {
        return `${index + 1} - ${label}`;
      }
      return label;
    },
    getOptions(feature) {
      if (feature.value_options && feature.value_options.options) {
        return feature.value_options.options;
      }
      return this.yesNoOptions;
    },
    validateRules(feature) {
      return true;
    },
    getDisplayProp(feature, prop, defaultValue) {
      const value = feature.feature.value_options && feature.feature.value_options.display && feature.feature.value_options.display[prop] ? feature.feature.value_options.display[prop] : defaultValue;
      return value;
    },
    castValue(value) {
      // Convert to lower case for case-insensitive comparison
      const lowerCaseValue = value.toLowerCase();

      if (lowerCaseValue === 'true') {
        return true; // Cast to boolean true
      } else if (lowerCaseValue === 'false') {
        return false; // Cast to boolean false
      }
      // Return the original value if it's neither "true" nor "false"
      return value;
    },
    checkFieldCondition(feature, current) {
      const { condition } = feature;
      if (!condition) return true;
      // we split the condition field:value
      const [field, value] = condition.split(':');
      // we check if the field exists in current object
      if (!current[field]) return false;
      // we check if the value is equal to the value in the current object
      if (current[field] == value) return true;

      return false;
    },
    checkCondition(feature) {
      if (!feature.condition) return true;

      const { condition } = feature;
      const relatedFeature = this.evaluationRequestData.features.find(
        (f) => f.feature_id === condition.related_feature_id,
      );

      // Ensure there is a related feature to compare against
      if (!relatedFeature) return false;

      // Cast the condition value to its appropriate type (boolean if 'true'/'false', string otherwise)
      const castedConditionValue = this.castValue(condition.value);

      // If the casted value is boolean, compare it directly
      if (typeof castedConditionValue === 'boolean') {
        return relatedFeature.value === castedConditionValue;
      }


      // If the condition value is a string (not strictly 'true' or 'false') and
      // the related feature's value is also a string or array of strings,
      // check if the related feature's value includes the condition value
      return this.isConditionMet(relatedFeature, castedConditionValue);
    },
    isConditionMet(relatedFeature, conditionValue) {
      // Cast the condition value to its appropriate type (boolean if 'true'/'false', string otherwise)
      const castedConditionValue = this.castValue(conditionValue);

      // Check if relatedFeature and relatedFeature.value are truthy
      if (!relatedFeature || relatedFeature.value === undefined || relatedFeature.value === null) {
        return false;
      }

      // Function to check if an object is empty
      const isEmptyObject = (obj) => Object.keys(obj).length === 0;

      // If the casted value is boolean, compare it directly
      if (typeof castedConditionValue === 'boolean') {
        return relatedFeature.value === castedConditionValue;
      }

      // Check if relatedFeature.value is an empty object
      if (typeof relatedFeature.value === 'object' && !Array.isArray(relatedFeature.value) && isEmptyObject(relatedFeature.value)) {
        return false;
      }

      // If relatedFeature.value is an Array, check for inclusion of castedConditionValue
      if (Array.isArray(relatedFeature.value)) {
        return relatedFeature.value.includes(castedConditionValue);
      }

      // If relatedFeature.value is a string, check if it includes castedConditionValue
      if (typeof relatedFeature.value === 'string') {
        return relatedFeature.value.includes(castedConditionValue);
      }

      // If relatedFeature.value is an Object (and not empty), check if any value matches castedConditionValue
      if (typeof relatedFeature.value === 'object') {
        return Object.values(relatedFeature.value).includes(castedConditionValue);
      }

      return false; // Default to false if none of the above conditions are met
    },
    getFeatureOptions(feature, features, currentFeaturesValues) {
      const { options } = feature.feature.value_options;
      // options has a condition if apply the filters: format is operator:related_feature
      if (!feature.feature.value_options.options_conditions) return options;
      const [operator, relatedFeatureName] = feature.feature.value_options.options_conditions.split(':');
      const condition = feature.feature.value_options.options_conditions;
      const relatedFeature = features.find(
        (f) => f.feature.name === relatedFeatureName,
      );
      const currentRelatedFeatureValue = currentFeaturesValues.find((f) => (Array.isArray(f)
        ? f.find((_f) => _f.feature_id === relatedFeature?.feature.id)
        : f.feature_id === relatedFeature?.feature.id));
      if (!currentRelatedFeatureValue) return options;
      switch (operator) {
        case '=': {
          // currentRelatedFeatureValue can be an array or a string
          if (Array.isArray(currentRelatedFeatureValue.value)) {
            return options.filter((option) => currentRelatedFeatureValue.value.includes(option));
          }
          return options.filter(
            (option) => option === currentRelatedFeatureValue.value,
          );
        }
        case '!':
        case '!=': {
          if (Array.isArray(currentRelatedFeatureValue.value)) {
            return options.filter(
              (option) => !currentRelatedFeatureValue.value.includes(value),
            );
          }
          return options.filter(
            (option) => option !== currentRelatedFeatureValue.value,
          );
        }
        case 'in': {
          if (Array.isArray(currentRelatedFeatureValue.value)) {
            return options.filter((option) => currentRelatedFeatureValue.value.includes(option));
          }
          return options.filter(
            (option) => option === currentRelatedFeatureValue.value,
          );
        }
      }
      return options;
    },
    featureChanged(feature) {
    },
    canReview() {
      return (
        this.$can('review', 'evaluation')
        && typeof ['تحت التّقييم', 'مفتوح', 'ملغى'].find(
          (status) => status.localeCompare(this.evaluationRequestData.status, 'ar') == 0,
        ) !== 'undefined'
      );
    },
    canSubmitForApproval() {
      return (
        this.$can('approve', 'evaluation')
        && typeof ['تم التّقييم'].find(
          (status) => status.localeCompare(this.evaluationRequestData.status, 'ar') == 0,
        ) !== 'undefined'
      );
    },
    calculateFeatureIndex(feature) {

      const index = this.evaluationRequestData.features.findIndex(
        (feat) => feat.feature_id == feature.property_feature_id,
      );

      return index;
    },
    validateLocation() {
      return new Promise((resolve, reject) => {
        this.$root.$on('locationValidated', ({ success, data }) => {
          if (success) {
            this.evaluationRequestData = { ...this.evaluationRequestData, ...data };
            resolve(true);
          } else {
            reject('الرجاء التأكد من صحة البيانات');
          }
        });
        this.$root.$emit('validateLocation');
      });
    },
    validateProperty() {
      return new Promise((resolve, reject) => {
        this.$root.$on('propertyValidated', ({ success, data }) => {
          if (success) {
            this.evaluationRequestData.features = this.updateCollection(
              this.evaluationRequestData.features,
              data.features,
            );
            resolve(true);
          } else {
            reject('الرجاء التأكد من صحة البيانات');
          }
        });
        this.$root.$emit('validateProperty');
      });
    },
    validateSpecifications() {
      return new Promise((resolve, reject) => {
        this.$root.$on('specificationsValidated', ({ success, data }) => {
          if (success) {
            this.evaluationRequestData.features = this.updateCollection(
              this.evaluationRequestData.features,
              data.features ?? [],
            );
            resolve(true);
          } else {
            reject('الرجاء التأكد من صحة البيانات');
          }
        });
        this.$root.$emit('validateSpecifications');
      });
    },
    validateEvaluation() {
      return new Promise((resolve, reject) => {
        this.$root.$on('evaluationValidated', ({ success, data }) => {
          if (success) {
            this.evaluationRequestData.comments = data.comments;
            this.evaluationRequestData.evaluated_at = data.evaluated_at;
            this.evaluationRequestData.property_description = data.property_description;
            this.evaluationRequestData.approximate_value_per_meter = data.approximate_value_per_meter;
            this.evaluationRequestData.value_per_meter = data.value_per_meter;
            this.evaluationRequestData.features = this.updateCollection(
              this.evaluationRequestData.features,
              data.features,
            );
            this.evaluationRequestData.comparatives = data.comparatives;
            this.evaluationRequestData.areas = data.areas;
            resolve(true);
          } else {
            reject('الرجاء التأكد من صحة البيانات');
          }
        });

        this.$root.$emit('validateEvaluation');
      });
    },
    validateOther() {
      return new Promise((resolve, reject) => {
        this.$root.$on('otherValidated', ({ success, data }) => {
          if (success) {
            this.evaluationRequestData.features = this.updateCollection(
              this.evaluationRequestData.features,
              data.features,
            );
            resolve(true);
          } else {
            reject('الرجاء التأكد من صحة البيانات');
          }
        });

        this.$root.$emit('validateOther');
      });
    },

    validationAttachmentsForm() {
      return new Promise((resolve, reject) => {
        this.$root.$on('attachmentsValidated', ({ success, data }) => {
          if (success) {
            this.evaluationRequestData = { ...this.evaluationRequestData, ...data, attachments: this.evaluationRequestData.attachments.concat(data.attachments) };
            resolve(true);
          } else {
            reject('الرجاء التأكد من صحة البيانات');
          }
        });

        this.$root.$emit('validateAttachments');
      });
    },
    getFeaturesByGroupNames,
    getCurrentFeatures,
    updateCollection,
    upsert,
  },
};
