<template>
  <div v-if="settlements && comparatives">
    <validation-observer #default="{ handleSubmit }" ref="propertyInfoRules">
      <b-form v-if="evaluationData" class="mt-1" @submit.prevent="handleSubmit(addEvaluation)" @reset.prevent="resetForm">
        <b-row>
          <b-col cols="12" md="12">
            <b-alert v-model="showMessage" dismissible :variant="messageType">
              <div class="alert-body">
                <feather-icon class="mr-25" icon="InfoIcon" />
                <span class="ml-25" v-html="message" />
              </div>
            </b-alert>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="12">
            <b-alert v-model="showMessage" dismissible :variant="messageType">
              <div class="alert-body">
                <feather-icon class="mr-25" icon="InfoIcon" />
                <span class="ml-25" v-html="message" />
              </div>
            </b-alert>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <div class="d-flex">
              <feather-icon icon="MapIcon" size="19" />
              <h4 class="mb-0 ml-50">
                معلومات عامة
              </h4>
            </div>
          </b-col>
          <b-col cols="12">
            <hr class="my-2">
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6" md="12" lg="6">
            <validation-provider #default="validationContext" vid="evaluated_at" name="evaluated_at" rules="required">
              <b-form-group label="تاريخ الوقوف على العقار" label-for="evaluated_at"
                :state="getValidationState(validationContext)">
                <flat-pickr v-model="evaluationData.evaluated_at" class="form-control" name="evaluated_at"
                  :config="config" placeholder="تاريخ الوقوف على العقار" />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col v-if="$can('approve', 'evaluation') && (evaluationData.status === 'تحت الإعتماد' || evaluationData.status === 'تم الاعتماد' || evaluationData.status === 'تم التّقييم')" cols="6" md="12" lg="6">
            <validation-provider #default="validationContext" vid="accredited_at" name="accredited_at" rules="required">
              <b-form-group label="تاريخ الإعتماد" label-for="accredited_at"
                :state="getValidationState(validationContext)">
                <flat-pickr v-model="evaluationData.accredited_at" class="form-control" name="evaluated_at"
                  :config="config" placeholder="تاريخ الاعتماد" />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="12" lg="12">
            <validation-provider #default="validationContext" vid="comments" name="comments">
              <b-form-group label="ملاحظات المقيّم" label-for="comments" :state="getValidationState(validationContext)">
                <b-form-textarea v-model="evaluationData.comments" placeholder="اكتب ملاحظاتك هنا ..." rows="3" name="comments"
                  max-rows="6" />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="12" lg="12">
            <validation-provider #default="validationContext" vid="property_description" name="property_description">
              <b-form-group label="وصف العقار" label-for="property_description"
                :state="getValidationState(validationContext)">
                <b-form-textarea v-model="evaluationData.property_description" placeholder="اكتب وصف العقار هنا ..."
                  rows="3" name="property_description" max-rows="6" />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <div class="d-flex">
              <feather-icon icon="MapIcon" size="19" />
              <h4 class="mb-0 ml-50">
                تقييم العقار
                <!-- Add item to areas -->
                <b-button class="btn-icon rounded-circle btn-sm" variant="primary"
                  style="width: 14px; height: 14px; padding: 0" size="sm" @click="addArea()">
                  <feather-icon icon="PlusIcon" size="10" />
                </b-button>
              </h4>
            </div>
          </b-col>
          <b-col cols="12">
            <hr class="my-2">
          </b-col>
        </b-row>
        <b-row>
          <b-col v-for="(item, i) in evaluationData.areas" :id="item.id" :key="i" ref="row" cols="12" md="12" lg="12">
            <b-row>
              <b-col>
                <div :class="{ 'd-flex align-items-center': !evaluationData.areas[i].showLabelField && !evaluationData.areas[i].label }">

                <!-- Field: area_id -->
                <validation-provider #default="validationContext" :vid="`area_id${i}`" :name="`area_id${i}`"
                  rules="required">
                  <b-form-group label="نوع المساحة" :label-for="`area_id${i}`"
                    :state="getValidationState(validationContext)">
                    <v-select v-model="evaluationData.areas[i].area_id"
                      :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'" :options="allAreas"
                      :reduce="(val) => val.value" :clearable="false" :input-id="`area_id${i}`"
                      @input="onAreaChange(item, i)" />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <b-button class="ml-1 btn-icon rounded-circle btn-sm" variant="primary" v-if="!evaluationData.areas[i].showLabelField && !evaluationData.areas[i].label"
                  style="width: 14px; height: 14px; padding: 0" size="sm" @click="showLabelField(i)">
                  <feather-icon icon="PlusIcon" size="10" />
                </b-button>        
            </div>
              </b-col>
              <b-col v-if="evaluationData.areas[i].label || evaluationData.areas[i].showLabelField">
                <div :class="{ 'd-flex align-items-center': evaluationData.areas[i].label  || evaluationData.areas[i].showLabelField }">
                <!-- Field: label -->
                <validation-provider #default="validationContext" :vid="`label-${i}`" :name="`label-${i}`">
                  <b-form-group label="مسمى المساحة" :label-for="`label-${i}`"
                    :state="getValidationState(validationContext)">
                    <b-form-input :id="`label-${i}`" v-model="evaluationData.areas[i].label" :value="item.name" />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <b-button class="ml-1 btn-icon rounded-circle btn-sm" variant="primary" 
                  style="width: 14px; height: 14px; padding: 0" size="sm" @click="hideLabelField(i)">
                  <feather-icon icon="XIcon" size="12" />
                </b-button>
              </div>
              </b-col>
              <b-col :key="`surface-${i}`" cols="12" md="2" lg="2">
                <!-- Field: area.surface -->
                <validation-provider #default="validationContext" :vid="`surface-${i}`" :name="`surface-${i}`">
                  <b-form-group label="المساحة" :label-for="`surface-${i}`"
                    :state="getValidationState(validationContext)">
                    <b-form-input :id="`surface-${i}`" v-model="evaluationData.areas[i].surface" type="number"
                      step="0.01" />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col :key="`value-${i}`" cols="12" md="2" lg="2">
                <!-- Field: area.value -->
                <validation-provider #default="validationContext" :vid="`area.value-${i}`" :name="`area.value-${i}`">
                  <b-form-group label="سعر المتر" :label-for="`area.value-${i}`"
                    :state="getValidationState(validationContext)">
                    <b-form-input :id="`area.value-${i}`" v-model="evaluationData.areas[i].value" type="number"
                      step="0.01" />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="2" lg="2" style="margin: auto">
                <span style="font-weight: bold">
                  {{
                    (
                      (evaluationData.areas[i].surface || 0) *
                      (evaluationData.areas[i].value || 0)
                    ).toFixed(2)
                  }}
                  ريال
                </span>
                <b-button v-if="i > 0" class="btn-icon" variant="danger" size="sm"
                  style="left: 20px; width: 18px; height: 18px; padding: 0" @click="removeArea(i)">
                  <feather-icon icon="XIcon" size="12" />
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <hr class="my-2">
          </b-col>
          <b-col cols="12" md="2" lg="2" style="margin: auto" class="text-success">
            <span> الكلفة التقديرية </span>
          </b-col>
          <b-col cols="12" md="8" lg="8" style="margin: auto" class="text-success">
            <span>
              {{ totalToWords() }}
            </span>
          </b-col>
          <b-col cols="12" md="2" lg="2" style="margin: auto" class="text-success">
            <span> {{ calculateTotal() }} ريال </span>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <hr class="my-2">
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <div class="d-flex">
              <feather-icon icon="GlobeIcon" size="19" />
              <h4 class="mb-0 ml-50">
                العقارات المقارنة
              </h4>
            </div>
          </b-col>
          <b-col cols="12">
            <hr class="my-2">
          </b-col>
          <b-col cols="12" md="12" lg="12">
            <b-row v-for="(item, k) in evaluationData.comparatives" :id="`comparatif-${k}`" :key="k">
              <b-col cols="12" md="12" lg="12">
                <div class="mb-1">
                  <h5 class="ml-50">
                    {{ comparativesLabels[k] }}
                  </h5>
                  <span class="text-success"><strong>إجمالي السعر: </strong>
                    {{
                      (evaluationData.comparatives[k].area || 0) *
                      (evaluationData.comparatives[k].price_per_meter || 0)
                    }}
                    ريال سعودي</span>
                </div>
              </b-col>
              <b-col cols="12" md="2" lg="2">
                <!-- Field: feature.description -->
                <validation-provider #default="validationContext" :vid="`feature.description-${k}`"
                  :name="`feature.description-${k}`">
                  <b-form-group label="وصف العقار" :label-for="`feature.description-${k}`"
                    :state="getValidationState(validationContext)">
                    <b-form-input :id="`feature.description-${k}`" v-model="evaluationData.comparatives[k].description" />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col v-for="(field, c) in comparatives[0].features" :id="field.id" :key="c" cols="12"
                :md="6 / comparatives[0].features.length" :lg="6 / comparatives[0].features.length">
                <!-- Field: field.feature.name -->
                <validation-provider #default="validationContext" :vid="`comparatives_${k}_${c}_${field.feature.name}`"
                  :name="`comparatives_${k}_${c}_${field.feature.name}`">
                  <b-form-group :label="field.feature.display_name"
                    :label-for="`comparatives_${k}_${c}_${field.feature.name}`"
                    :state="getValidationState(validationContext)">
                    <v-select v-if="field.feature.value_type === 'enum'"
                      :id="`comparatives_${k}_${c}_${field.feature.name}`"
                      v-model="evaluationData.comparatives[k]['features'][c].value"
                      :close-on-select="!field.feature.value_options.multiple"
                      :multiple="field.feature.value_options.multiple"
                      :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                      :options="field.feature.value_options.options" :clearable="true" />
                    <b-form-input 
                      v-else :id="`comparatives_${k}_${c}_${field.feature.name}`"
                      v-model="evaluationData.comparatives[k]['features'][c].value"
                      :placeholder=" field.feature.name === 'location' ? '24.700151, 46.733309' : null"
                       />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="2" lg="2">
                <!-- Field: feature.area -->
                <validation-provider #default="validationContext" :vid="`feature.area-${k}`" :name="`feature.area-${k}`">
                  <b-form-group label="المساحة" :label-for="`feature.area-${k}`"
                    :state="getValidationState(validationContext)">
                    <b-form-input :id="`feature.area-${k}`" v-model="evaluationData.comparatives[k].area"  type="number" step="0.01" />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="2" lg="2">
                <!-- Field: feature.price_per_meter-->
                <validation-provider #default="validationContext" :vid="`feature.price_per_meter-${k}`"
                  :name="`feature.price_per_meter-${k}`">
                  <b-form-group label="سعر المتر" :label-for="`feature.price_per_meter-${k}`"
                    :state="getValidationState(validationContext)" >
                    <b-form-input :id="`feature.price_per_meter-${k}`"
                      v-model="evaluationData.comparatives[k].price_per_meter" type="number"
                      step="0.01"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row key="settlement-table">
          <b-col cols="12" md="12" lg="12">
            <div class="d-flex">
              <feather-icon icon="GlobeIcon" size="19" />
              <h4 class="mb-0 ml-50">
                جدول التسويات
                <!-- Add item to areas -->
                <b-button class="btn-icon rounded-circle btn-sm" variant="primary"
                  style="width: 14px; height: 14px; padding: 0" size="sm" @click="addSettlment()">
                  <feather-icon icon="PlusIcon" size="10" />
                </b-button>
              </h4>
            </div>
          </b-col>
          <b-col cols="12">
            <hr class="my-2">
          </b-col>
          <b-col>
            <b-table-simple hover striped bordered responsive>
              <b-thead head-variant="light">
                <b-tr class="text-center">
                  <b-th colspan="1">
                    &nbsp;
                  </b-th>
                  <b-th colspan="2">
                    المقارن الأول
                  </b-th>
                  <b-th colspan="2">
                    المقارن الثاني
                  </b-th>
                  <b-th colspan="2">
                    المقارن الثالث
                  </b-th>
                </b-tr>
                <b-tr v-for="(
                    field, c
                  ) in evaluationData.comparatives[0].settlments.slice(
                    0,
                    settlements[0].settlemts1.length
                  )" :id="field.id" :key="`settlment-1-date-${c}`" class="text-center">
                  <b-td v-if="settlements[0].settlemts1[c].feature.value_type == 'date'
                    " class="text-bold text-left">
                    {{ settlements[0].settlemts1[c].feature.display_name }}
                  </b-td>
                  <template v-for="(comparative, k) in evaluationData.comparatives">
                    <b-td v-if="settlements[0].settlemts1[c].feature.value_type == 'date'
                      " :key="`date-${k}-${c}`" colspan="2">
                      <validation-provider #default="validationContext"
                        :key="`feature.${settlements[0].settlemts1[c].feature.name}_${k}_${c}`"
                        :vid="`feature.${settlements[0].settlemts1[c].feature.name}_${k}_${c}`"
                        :name="`feature.${settlements[0].settlemts1[c].feature.name}_${k}_${c}`">
                        <b-form-group :state="getValidationState(validationContext)">
                          <flat-pickr :id="`feature.${settlements[0].settlemts1[c].feature.name}_${k}_${c}`" v-model="evaluationData.comparatives[k].settlments[c].value
                            " :config="config" class="form-control edit-input" />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-td>
                  </template>
                </b-tr>
                <b-tr class="text-center">
                  <b-th />
                  <b-th>القيمة</b-th>
                  <b-th>نسبةالتسوية</b-th>
                  <b-th>القيمة</b-th>
                  <b-th>نسبةالتسوية</b-th>
                  <b-th>القيمة</b-th>
                  <b-th>نسبةالتسوية</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(field, c) in settlements[0].settlemts1" :id="field.id" :key="`settlment1-1-${c}`"
                  class="text-center">
                  <b-th v-if="['string', 'object'].includes(field.feature.value_type)" class="text-bold text-left">
                    {{ field.feature.display_name }}
                  </b-th>
                  <template v-for="k in [0, 1, 2]">
                    <b-td v-if="field.feature.value_type == 'string'" :key="`settlemts1-input-${k}-${c}`" colspan="2">
                      <validation-provider #default="validationContext" :key="`td-${k}`"
                        :vid="`feature.${field.feature.name}-${k}-${c}`"
                        :name="`feature.${field.feature.name}-${k}-${c}`">
                        <b-form-group :state="getValidationState(validationContext)">
                          <b-form-input :id="`feature.${field.feature.name}-${k}-${c}`" v-model="evaluationData.comparatives[k].settlments[c].value
                            " />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-td>
                    <template v-if="field.feature.value_type == 'object'">
                      <b-td v-for="(subField, s) in field.feature.value_options
                        .fields" :key="`subfield-${s}-${k}`">
                        <validation-provider #default="validationContext" :key="`td-${s}-${k}`"
                          :vid="`feature.${subField.name}-${s}-${c}`" :name="`feature.${subField.name}-${s}-${c}`">
                          <b-form-group :state="getValidationState(validationContext)">
                            <b-form-input v-if="subField.value_type == 'string'"
                              :id="`feature.${subField.name}-${s}-${c}`" v-model="evaluationData.comparatives[k].settlments[c]
                                  .value[subField.name]
                                " />
                            <b-form-input v-if="subField.value_type == 'integer'"
                              :id="`feature.${subField.name}-${s}-${c}`" v-model="evaluationData.comparatives[k].settlments[c]
                                  .value[subField.name]
                                " type="number" />
                            <v-select v-if="subField.value_type === 'enum'" v-model="evaluationData.comparatives[k].settlments[c]
                                .value[subField.name]
                              " :dir="$store.state.appConfig.layout.isRTL
      ? 'rtl'
      : 'ltr'
    " :options="subField.value_options.options" :clearable="true" :input-id="`feature.${subField.name}-${s}-${c}`" />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-td>
                    </template>
                  </template>
                </b-tr>
                <b-tr class="text-center">
                  <b-th class="text-bold text-left">
                    اجمالي نسب التسويات
                  </b-th>
                  <b-td colspan="2">
                    {{ calculatePrimaryTotalAdjustment(0) }}%
                  </b-td>
                  <b-td colspan="2">
                    {{
                      calculatePrimaryTotalAdjustment(1)
                    }}
                  </b-td>
                  <b-td colspan="2">
                    {{
                      calculatePrimaryTotalAdjustment(2)
                    }}
                  </b-td>
                </b-tr>
                <b-tr class="text-center">
                  <b-th class="text-bold text-left">
                    قيمة نسب التسويات
                  </b-th>
                  <b-td colspan="2">
                    {{
                      calculatePrimaryAdjustmentValue(0)
                    }}
                  </b-td>
                  <b-td colspan="2">
                    {{
                      calculatePrimaryAdjustmentValue(1)
                    }}
                  </b-td>
                  <b-td colspan="2">
                    {{
                      calculatePrimaryAdjustmentValue(2)
                    }}
                  </b-td>
                </b-tr>
                <b-tr class="text-center">
                  <b-th class="text-bold text-left">
                    السعر بعد التعديلات
                  </b-th>
                  <b-td colspan="2">
                    {{
                      calculatePrimaryPriceAfterAdjustments(0)
                    }}
                  </b-td>
                  <b-td colspan="2">
                    {{
                      calculatePrimaryPriceAfterAdjustments(1)
                    }}
                  </b-td>
                  <b-td colspan="2">
                    {{
                      calculatePrimaryPriceAfterAdjustments(2)
                    }}
                  </b-td>
                </b-tr>
                <b-tr v-for="(
                    field, c
                  ) in evaluationData.comparatives[0].settlments.slice(
                    settlements[0].settlemts1.length
                  )" :id="settlements[0].settlemts2[
    getSettlmentIndex(field.property_feature_id)
  ].id
  " :key="`settlment2-1-${c}`" style="position: relative" class="text-center">
                  <b-th v-if="c > 0" class="text-bold text-left">
                    <validation-provider #default="validationContext" :key="`settlemts2-td-${c}`" :vid="`feature.${settlements[0].settlemts2[
                        getSettlmentIndex(field.property_feature_id)
                      ].feature.name
                      }-${c}`" :name="`feature.${settlements[0].settlemts2[
      getSettlmentIndex(field.property_feature_id)
    ].feature.name
    }-${c}`">
                      <b-form-group :state="getValidationState(validationContext)">
                        <b-form-input :id="`feature.${settlements[0].settlemts2[
                            getSettlmentIndex(field.property_feature_id)
                          ].feature.name
                          }-${c}`" v-model="evaluationData.comparatives[0].settlments[
      c + settlements[0].settlemts1.length
    ].label
    " style="width: auto" />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-th>
                  <b-th v-if="c == 0" class="text-bold text-left">
                    {{
                      settlements[0].settlemts2[
                        getSettlmentIndex(field.property_feature_id)
                      ].feature.display_name
                    }}
                  </b-th>
                  <template v-for="k in [0, 1, 2]">
                    <b-td v-if="settlements[0].settlemts2[
                        getSettlmentIndex(field.property_feature_id)
                      ].value_type == 'string'
                      " :key="`settlemts2-input-${k}-${c}`" colspan="2">
                      <validation-provider #default="validationContext" :key="`td-${k}-${c}`" :vid="`feature.${settlements[0].settlemts2[
                          getSettlmentIndex(field.property_feature_id)
                        ].feature.name
                        }-${k}-${c}`" :name="`feature.${settlements[0].settlemts2[
      getSettlmentIndex(field.property_feature_id)
    ].feature.name
    }-${k}-${c}`">
                        <b-form-group :state="getValidationState(validationContext)">
                          <b-form-input :id="`feature.${settlements[0].settlemts2[
                              getSettlmentIndex(field.property_feature_id)
                            ].feature.name
                            }-${k}-${c}`" v-model="evaluationData.comparatives[k].settlments[
      c + settlements[0].settlemts1.length
    ].value
    " />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-td>
                    <template v-if="settlements[0].settlemts2[
                        getSettlmentIndex(field.property_feature_id)
                      ].feature.value_type == 'object'
                      ">
                      <b-td v-for="(subField, s) in settlements[0].settlemts2[
                        getSettlmentIndex(field.property_feature_id)
                      ].feature.value_options.fields" :key="`subfield-${s}-${k}`">
                        <validation-provider #default="validationContext" :key="`td-${s}-${k}`"
                          :vid="`feature.${subField.name}-${s}-${c}`" :name="`feature.${subField.name}-${s}-${c}`">
                          <b-form-group :state="getValidationState(validationContext)">
                            <span v-if="subField.value_type == 'readonly'">
                              {{ getRelatedValue(subField, k) }}</span>

                            <b-form-input v-if="subField.value_type == 'string'"
                              :id="`feature.${subField.name}-${s}-${c}`" v-model="evaluationData.comparatives[k].settlments[
                                  c + settlements[0].settlemts1.length
                                ].value[subField.name]
                                " />
                            <b-form-input v-if="subField.value_type == 'integer'"
                              :id="`feature.${subField.name}-${s}-${c}`" v-model="evaluationData.comparatives[k].settlments[
                                  c + settlements[0].settlemts1.length
                                ].value[subField.name]
                                " type="number" />
                            <v-select v-if="subField.value_type === 'enum'" v-model="evaluationData.comparatives[k].settlments[
                                c + settlements[0].settlemts1.length
                              ].value[subField.name]
                              " :dir="$store.state.appConfig.layout.isRTL
      ? 'rtl'
      : 'ltr'
    " :options="subField.value_options.options" :clearable="true" :input-id="`feature.${subField.name}-${s}-${c}`" />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-td>
                    </template>
                  </template>
                  <b-button v-if="c > 0" class="btn-icon" variant="danger" size="sm" style="position: absolute;
                    top: 20px;
                    left: 6px;
                    width: 18px;
                    height: 18px;
                    padding: 0;
                  " @click="removeSettlment(c + settlements[0].settlemts1.length)">
                    <feather-icon icon="XIcon" size="12" />
                  </b-button>
                </b-tr>
                <b-tr class="text-center">
                  <b-th class="text-bold text-left">
                    اجمالي نسب التسويات
                  </b-th>
                  <b-td colspan="2">
                    {{ calculateTotalAdjustment(0) }} %
                  </b-td>
                  <b-td colspan="2">
                    {{ calculateTotalAdjustment(1) }} %
                  </b-td>
                  <b-td colspan="2">
                    {{ calculateTotalAdjustment(2) }} %
                  </b-td>
                </b-tr>
                <b-tr class="text-center">
                  <b-th class="text-bold text-left">
                    قيمة نسب التسويات
                  </b-th>
                  <b-td colspan="2">
                    {{ calculateAdjustmentValue(0) }}
                  </b-td>
                  <b-td colspan="2">
                    {{ calculateAdjustmentValue(1) }}
                  </b-td>
                  <b-td colspan="2">
                    {{ calculateAdjustmentValue(2) }}
                  </b-td>
                </b-tr>
                <b-tr class="text-center">
                  <b-th class="text-bold text-left">
                    السعر بعد التعديلات
                  </b-th>
                  <b-td colspan="2">
                    {{ calculatePriceAfterAdjustments(0) }}
                  </b-td>
                  <b-td colspan="2">
                    {{ calculatePriceAfterAdjustments(1) }}
                  </b-td>
                  <b-td colspan="2">
                    {{ calculatePriceAfterAdjustments(2) }}
                  </b-td>
                </b-tr>
                <!-- relative_adjustment  الضبط النسبي -->
               <b-tr>
                  <b-th class="text-bold text-left">
                    الضبط النسبي
                  </b-th>
                  <b-td colspan="2">
                    <validation-provider #default="validationContext" :vid="`comparative_relative_adjustment-0`"
                  :name="`comparative_relative_adjustment-0`">
                  <b-form-group label="الضبط النسبي" :label-for="`comparative_relative_adjustment-0`"
                    :state="getValidationState(validationContext)">
                    <b-form-input type="number" :id="`comparative_relative_adjustment-0`" v-model="evaluationData.comparatives[0].relative_adjustment" />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                  </b-td>
                  <b-td colspan="2">
                    <validation-provider #default="validationContext" :vid="`comparative_relative_adjustment-1`"
                  :name="`comparative_relative_adjustment-1`">
                  <b-form-group label="الضبط النسبي" :label-for="`comparative_relative_adjustment-1`"
                    :state="getValidationState(validationContext)">
                    <b-form-input type="number" :id="`comparative_relative_adjustment-1`" v-model="evaluationData.comparatives[1].relative_adjustment" />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                  </b-td>
                  <b-td colspan="2">
                    <validation-provider #default="validationContext" :vid="`comparative_relative_adjustment-2`"
                  :name="`comparative_relative_adjustment-2`">
                  <b-form-group label="الضبط النسبي" :label-for="`comparative_relative_adjustment-2`"
                    :state="getValidationState(validationContext)">
                    <b-form-input type="number" :id="`comparative_relative_adjustment-2`" v-model="evaluationData.comparatives[2].relative_adjustment" />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                  </b-td>
                </b-tr>
                  <!-- قيمة المتر -->
                  <b-tr class="text-center">
                <b-th class="text-bold text-left">
                  قيمة المتر
                </b-th>
                <b-td colspan="6">
                  {{ calculateValuePerMeter() }}
                </b-td>
              </b-tr>
              <!-- approximate_value_per_meter -->
              <b-tr class="text-center">
                <b-th class="text-bold text-left">
                  قيمة المتر تقريبا	
                </b-th>
                <b-td colspan="6">
                  <validation-provider #default="validationContext" :vid="`evaluation.approximate_value_per_meter`"
                  :name="`evaluation.approximate_value_per_meter`">
                  <b-form-group label="قيمة المتر تقريبا" :label-for="`evaluation.approximate_value_per_meter`"
                    :state="getValidationState(validationContext)">
                    <b-form-input type="number" :id="`evaluation.approximate_value_per_meter`" v-model="evaluationData.approximate_value_per_meter" />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
        <b-row v-for="(group, index) in features" :id="group.id" :key="index">
          <b-col cols="12" md="12" lg="12">
            <div class="d-flex">
              <feather-icon icon="GlobeIcon" size="19" />
              <h4 class="mb-0 ml-50">
                {{ group.display_name }}
              </h4>
            </div>
          </b-col>
          <b-col cols="12">
            <hr class="my-2">
          </b-col>
          <template v-for="(feature, j) in group.features">
          <b-col :id="feature.id" :key="j" cols="12"
            :lg="feature.feature.value_type === 'object' ? 12 : getDisplayProp(feature, 'lg', 6)"
            :md="feature.feature.value_type === 'object' ? 12 : getDisplayProp(feature, 'md', 6)">
            <!-- Object Type -->
            <template v-if="feature.feature.value_type === 'object'">
              <b-row>
                <h4 class="mb-0 ml-50">
                  <b-col cols="12">
                    <b-form-group :label="feature.feature.display_name">
                    </b-form-group>
                  </b-col>
                </h4>
              </b-row>
              <b-row>
                <template v-for="(nestedFeature, nestedIndex) in feature.feature.value_options.fields">
                  <b-col :key="nestedIndex">
                    <validation-provider
                      v-if="checkFieldCondition(nestedFeature, evaluationData.features[calculateFeatureIndex(feature)].value)"
                      #default="validationContext" :vid="`${nestedFeature.name}_${j}_${nestedIndex}`"
                      :name="`${nestedFeature.name}_${j}_${nestedIndex}`">
                      <b-form-group :label="nestedFeature.display_name"
                        :label-for="`${nestedFeature.name}_${j}_${nestedIndex}`"
                        :state="getValidationState(validationContext)">
                        <b-input-group v-if="nestedFeature.value_type === 'computed'">
                          <b-form-input type="text" disabled
                            :value="computeResult(nestedFeature.formula, nestedFeature.name, feature.feature.value_options.fields, evaluationData.features[calculateFeatureIndex(feature)].value)"
                            v-model="evaluationData.features[calculateFeatureIndex(feature)].value[nestedFeature.name]" />
                          <b-input-group-append v-if="nestedFeature.suffix" is-text>
                            {{ nestedFeature.suffix }}
                          </b-input-group-append>
                        </b-input-group>
                        <!-- Enum Type -->
                        <v-select v-else-if="nestedFeature.value_type === 'enum'"
                          v-model="evaluationData.features[calculateFeatureIndex(feature)].value[nestedFeature.name]"
                          :close-on-select="!nestedFeature.value_options.multiple"
                          :multiple="nestedFeature.value_options.multiple"
                          :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                          :options="nestedFeature.value_options.options" :clearable="true" :input-id="`${nestedFeature.name}_${j}_${nestedIndex}`"
                          @input="featureChanged" />

                        <!-- Boolean Type -->
                        <b-form-radio-group v-else-if="nestedFeature.value_type === 'boolean'"
                          v-model="evaluationData.features[calculateFeatureIndex(feature)].value[nestedFeature.name]"
                          :options="getOptions(nestedFeature)" class="demo-inline-spacing"
                          :name="`${nestedFeature.name}_${j}_${nestedIndex}`" />

                        <!-- Text Type -->
                        <b-form-textarea v-else-if="nestedFeature.value_type === 'text'" :id="nestedFeature.name"
                          v-model="evaluationData.features[calculateFeatureIndex(feature)].value[nestedFeature.name]" />

                        <!-- Range Type -->
                        <b-form-input
                          v-else-if="nestedFeature.value_type === 'range' && nestedFeature.value_options && nestedFeature.value_options.min !== undefined && nestedFeature.value_options.max !== undefined"
                          type="number"
                          v-model="evaluationData.features[calculateFeatureIndex(feature)].value[nestedFeature.name]"
                          :min="nestedFeature.value_options.min" :max="nestedFeature.value_options.max" />
                        <flat-pickr v-else-if="nestedFeature.value_type === 'range'"
                          v-model="evaluationData.features[calculateFeatureIndex(feature)].value"
                          :config="{ mode: 'range' }"
                          class="form-control flat-picker bg-transparent border-0 shadow-none"
                          placeholder="YYYY-MM-DD" />

                        <!-- Date Type -->
                        <flat-pickr v-else-if="nestedFeature.value_type === 'date'"
                          v-model="evaluationData.features[calculateFeatureIndex(feature)].value[nestedFeature.name]"
                          :config="datePickerConfig"
                          class="form-control flat-picker bg-transparent border-0 shadow-none"
                          placeholder="YYYY-MM-DD" />

                        <!-- Integer, String, Coordinates (and others not explicitly handled) -->
                        <b-form-input v-else :id="nestedFeature.name"
                          v-model="evaluationData.features[calculateFeatureIndex(feature)].value[nestedFeature.name]"
                          :type="feature.feature.value_type === 'integer' ? 'number' : 'text'" />

                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </template>
              </b-row>
            </template>

            <template v-else>
             <validation-provider v-if="checkCondition(feature.feature)" #default="validationContext"
                :vid="feature.feature.name" :name="feature.feature.name">
                <b-form-group :label="getFeatureLabel(group, j, feature.feature.display_name)"
                  :label-for="feature.feature.name" :state="getValidationState(validationContext)">
                  <b-input-group v-if="feature.feature.value_type === 'computed'">
                    <b-form-input type="text" disabled
                      :value="computeFieldsResult(feature.feature.value_options.formula, 'value', group.features, evaluationData.features, evaluationData.features[calculateFeatureIndex(feature)])"
                      v-model="evaluationData.features[calculateFeatureIndex(feature)].value" />
                    <b-input-group-append v-if="feature.feature.value_options.suffix" is-text>
                      {{ feature.feature.value_options.suffix }}
                    </b-input-group-append>
                  </b-input-group>
                  <!-- Enum Type -->
                  <v-select v-else-if="feature.feature.value_type === 'enum'"
                    v-model="evaluationData.features[calculateFeatureIndex(feature)].value"
                    :close-on-select="!feature.feature.value_options.multiple"
                    :multiple="feature.feature.value_options.multiple"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="feature.feature.value_options.options" :clearable="true" 
                    @input="featureChanged" />

                  <!-- Boolean Type -->
                  <b-form-radio-group v-else-if="feature.feature.value_type === 'boolean'"
                    v-model="evaluationData.features[calculateFeatureIndex(feature)].value"
                    :options="getOptions(feature.feature)" class="demo-inline-spacing" :name="feature.feature.name" />

                  <!-- Text Type -->
                  <b-form-textarea v-else-if="feature.feature.value_type === 'text'" :id="feature.feature.name"
                    v-model="evaluationData.features[calculateFeatureIndex(feature)].value" />

                  <!-- Range Type -->
                  <b-form-input
                    v-else-if="feature.feature.value_type === 'range' && feature.feature.value_options && feature.feature.value_options.min !== undefined && feature.feature.value_options.max !== undefined"
                    type="number" v-model="evaluationData.features[calculateFeatureIndex(feature)].value"
                    :min="feature.feature.value_options.min" :max="feature.feature.value_options.max" />
                  <flat-pickr v-else-if="feature.feature.value_type === 'range'"
                    v-model="evaluationData.features[calculateFeatureIndex(feature)].value" :config="{ mode: 'range' }"
                    class="form-control flat-picker bg-transparent border-0 shadow-none" placeholder="YYYY-MM-DD" />

                  <!-- Date Type -->
                  <flat-pickr v-else-if="feature.feature.value_type === 'date'"
                    v-model="evaluationData.features[calculateFeatureIndex(feature)].value" :config="datePickerConfig"
                    class="form-control flat-picker bg-transparent border-0 shadow-none" placeholder="YYYY-MM-DD" />

                  <!-- Integer, String, Coordinates (and others not explicitly handled) -->
                  <b-form-input v-else :id="feature.feature.name"
                    v-model="evaluationData.features[calculateFeatureIndex(feature)].value"
                    :type="feature.feature.value_type === 'integer' ? 'number' : 'text'" />

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </template>
          </b-col>
        </template>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>
<script >
import {
  BButton,
  BMedia,
  BAlert,
  BRow,
  BCol,
  BFormGroup,
  BFormRadioGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm,
  BTable,
  BThead,
  BTr,
  BTh,
  BTableSimple,
  BTbody,
  BTd,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref, onUnmounted, onMounted } from '@vue/composition-api';
import { useEvaluation } from '@/composables/use-evaluation';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import store from '@/store';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import { nArabicWords } from 'narabicwords';
import { evaluationMixin } from '@/@core/mixins/modules/evaluation';
import { propertyEvaluationMixin } from '@/@core/mixins/modules/evaluation/propertyEvaluation';
import evaluationStore from '../../evaluationStore';

export default {
  components: {
    BButton,
    BMedia,
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BForm,
    BTable,
    BThead,
    BTr,
    BTh,
    BTableSimple,
    BTbody,
    BTd,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormInvalidFeedback,
    BFormTextarea,
    vSelect,
    flatPickr,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [alertMessageMixin, evaluationMixin, propertyEvaluationMixin],
  props: {
    evaluation: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      config: {
        locale: Arabic,
      },
      fields: ['المقارن الاول', 'المقارن الثاني', 'المقارن الثالث'],
      settlement: {},
      isLoading: false,
      required,
      alphaNum,
      validEmail,
      comparativesLabels: ['المقارن الأول', 'المقارن الثاني', 'المقارن الثالث'],
    };
  },
  mounted() {
    this.$root.$on('validateEvaluation', async () => {
      this.$root.$emit('evaluationValidated', {
        success: true,
        data: this.evaluationData,
      });
    });
  },
  beforeDestroy() {
    this.$root.$off('validateProperty');
  },
  methods: {
    showLabelField(index) {
      // Set showLabelField to true for the specified index to display the label field
      this.$set(this.evaluationData.areas[index], 'showLabelField', true);
    },
    hideLabelField(index) {
      // Set showLabelField to true for the specified index to display the label field
      this.$set(this.evaluationData.areas[index], 'showLabelField', false);
      this.$delete(this.evaluationData.areas[index], 'label');  
      },
    // Get feature label
    getFeatureLabel(group, index, label) {
      // if group.options.is_ordered_list exists and its true we prefix label with current number starting from 1
      if (group.options && group.options.is_ordered_list) {
        return `${index + 1} - ${label}`;
      }
      return label;
    },
    // Get related value
    getRelatedValue(settlment, parentIndex) {
      // get related field name from settlment value_options.from
      const relatedFieldName = settlment.value_options.from;
      // check if field is nested object
      if (relatedFieldName.includes('.')) {
        // get the nested object name
        const nestedObjectName = relatedFieldName.split('.')[0];
        // if nested object is plural then its an object
        if (nestedObjectName.endsWith('s')) {
          // get the nested object value
          const nestedObjectValue = this.evaluationData[nestedObjectName][parentIndex];
          // get the nested object field name
          const nestedObjectFieldName = relatedFieldName.split('.')[1];
          // get the nested object field value
          const nestedObjectFieldValue = nestedObjectValue[nestedObjectFieldName];
          // return the nested object field value
          return nestedObjectFieldValue;
        }
        // get the nested object value
        const nestedObjectValue = this.evaluationData[nestedObjectName][parentIndex];
        // get the nested object field name
        const nestedObjectFieldName = relatedFieldName.split('.')[1];
        // get the nested object field value
        const nestedObjectFieldValue = nestedObjectValue[nestedObjectFieldName];
        // return the nested object field value
        return nestedObjectFieldValue;
      }
      // get the related field value
      const relatedFieldValue = this.evaluationData.comparatives[parentIndex][relatedFieldName];
      // return the related field value
      return relatedFieldValue;
    },
    // Get evaluation feature index by feature name
    getFeatureIndex(featureId) {
      return this.evaluationData.features.findIndex(
        (feature) => feature.feature_id === featureId,
      );
    },
    totalToWords() {
      const total = this.calculateTotal();
      // We split the total to get the integer and decimal parts
      const [integer, decimal] = total.split('.');
      let totalWords = `${nArabicWords(integer)}`;
      if (decimal) totalWords += ` فاصل ${nArabicWords(decimal)}`;
      return `${totalWords} ريال سعودي`;
    },
    calculateTotal() {
      let total = 0;
      this.evaluationData.areas.forEach((area) => {
        total += (area.surface || 0) * (area.value || 0);
      });
      return total.toFixed(2);
    },
    featureChanged(feature) {
    },
  },
  setup(props) {
    const { allAreas, loadAllAreas } = useEvaluation();
    const { evaluation } = props;
    const { property, request } = evaluation;
    const { type } = property;
    const EVALUATION_STORE_MODULE_NAME = 'evaluation';
    const evaluationData = ref(evaluation);
    // Register evaluation
    if (!store.hasModule(EVALUATION_STORE_MODULE_NAME)) store.registerModule(EVALUATION_STORE_MODULE_NAME, evaluationStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVALUATION_STORE_MODULE_NAME)) store.unregisterModule(EVALUATION_STORE_MODULE_NAME);
    });
    onMounted(async () => {
      await loadAllAreas();
    })

    const features = ref([]);
    const comparatives = ref(null);
    const settlements = ref(null);
    const areas = ref([]);
    const otherSettlment = ref({});

    let blankEvaluationData;
    if (type) {
      // Retrieve related features
      store
          .dispatch('evaluation/fetchFeatures', { id: type.id })
          .then((response) => {
            const { data } = response.data;
            features.value = data.groups
              .filter((group) => ['property_notes'].includes(group.name))
              .map((group) => {
                group.features = group.features
                  .filter((feature) => feature.property_type_id == type.id)
                  .map((feature) => {
                    if (feature.value_type == 'enum') {
                      feature.value_options.options = feature.value_options.options.map((option) => ({
                        value: option,
                        label: option,
                      }));
                    }
                    return feature;
                  });
                return group;
              });
            comparatives.value = data.groups
              .filter((group) => ['property_comparative'].includes(group.name))
              .map((group) => {
                group.features = group.features
                  .map((feature) => {
                    if (feature.value_type == 'enum') {
                      feature.value_options.options = feature.value_options.options.map((option) => ({
                        value: option,
                        label: option,
                      }));
                    }
                    return feature;
                  });
                return group;
              });

            settlements.value = data.groups
              .filter((group) => ['property_settlements'].includes(group.name))
              .map((group) => {
                group.features = group.features
                  .filter((feature) => feature.property_type_id == type.id)
                  .map((feature) => {
                    if (feature.value_type == 'enum') {
                      feature.value_options.options = feature.value_options.options.map((option) => ({
                        value: option,
                        label: option,
                      }));
                    }
                    if (feature.value_type == 'object') {
                      feature.fields.map((field) => {
                        if (field.value_type == 'enum') {
                          field.value_options.options = field.value_options.options.map((option) => ({
                            value: option,
                            label: option,
                          }));
                        }
                        return field;
                      });
                    }
                    return feature;
                  });
                group.settlemts1 = group.features.filter((feature) => [
                  'transaction_date',
                  'market_conditions',
                  'financing_conditions',
                  'sale_conditions',
                ].includes(feature.feature.name));
                group.settlemts2 = group.features.filter(
                  (feature) => ![
                    'transaction_date',
                    'market_conditions',
                    'financing_conditions',
                    'sale_conditions',
                  ].includes(feature.feature.name),
                );
                return group;
              });
              otherSettlment.value = settlements.value[0].settlemts2.find(
            (item) => item.feature.name == 'other',
          );


            store
              .dispatch('evaluation/fetchAreas', { id: type.id })
              .then((response) => {
                const { data: areasData } = response.data;
                areas.value = areasData.map((area) => ({
                  value: area.id,
                  label: area.name,
                }));
                
              })
              .catch((error) => {
                if (error.response && error.response.status === 404) {
                  evaluationData.value = undefined;
                } else {
                  console.log(error);
                }
              });
          })
          .catch((error) => {
            if (error.response && error.response.status === 404) {
              evaluationData.value = undefined;
            } else {
              console.log(error);
            }
          });
      }

      blankEvaluationData = evaluation;
    

    const resetevaluationData = () => {
      evaluationData.value = JSON.parse(JSON.stringify(blankEvaluationData));
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetevaluationData);

    return {
      request,
      areas,
      allAreas,
      features,
      comparatives,
      settlements,
      evaluationData,
      refFormObserver,
      getValidationState,
      resetForm,
      otherSettlment,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
